import React, { useEffect, useRef, useState } from "react"
import { Col } from "react-bootstrap"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import * as styles from "./Hiring.module.scss"

function Hiring() {
  const [isInView, setIsInView] = useState(false)
  const sectionRef = useRef(null)
  const imgRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsInView(true)
        } else {
          setIsInView(false)
        }
      },
      { threshold: 0.1 }
    )

    if (sectionRef.current) {
      observer.observe(sectionRef.current)
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current)
      }
    }
  }, [])

  useEffect(() => {
    if (isInView && imgRef.current) {
      const currentSrc = imgRef.current.src
      imgRef.current.src = ""
      imgRef.current.src = currentSrc
    }
  }, [isInView])

  return (
    <div className={styles.hiring} ref={sectionRef}>
      <Container>
        <p className={styles.heading}>Process</p>
        <h2 className={styles.mainHeading}>
          Hiring Your IT Staff Augmentation Team the InvoZone Way
        </h2>
        <div className={styles.hiringGif}>
          {isInView && (
            <video
              className={styles.backgroundVideo}
              autoPlay
              muted
              // loop
              // poster="https://invozone-backend.s3.us-east-1.amazonaws.com/Screenshot_2024_07_22_233957_b57448a23f.webp"
            >
              <source
                src="https://invozone-backend.s3.us-east-1.amazonaws.com/ezgif_2_461fd02a64_1_903bf1bcda.webm"
                type="video/mp4"
              />
            </video>
          )}
        </div>
      </Container>
    </div>
  )
}

export default Hiring

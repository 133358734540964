import React from "react"
import Container from "react-bootstrap/Container"
import { Link } from "gatsby"
import * as styles from "./Solutions.module.scss"

const Solutions = ({ strapiData }) => {
  return (
    <div className={`${styles.subserviceVisionIOS}`}>
      <Container className={styles.con}>
        <div className={styles.Ready}>
          <h2
            className={styles.heading}
            dangerouslySetInnerHTML={{ __html: strapiData?.title }}
          />
          <div className={`${styles.bannerBtn}`}>
            {strapiData?.buttons[0] && (
              <Link
                to={strapiData?.buttons[0]?.url}
                className={styles.btn_white2_border_Vision_IOS}
              >
                {strapiData?.buttons[0]?.title}
                <img
              src="https://invozone-backend.s3.us-east-1.amazonaws.com/Frame_1_cc51769d02.svg"
              decoding="async"
              loading="lazy"
              alt="explore icon"
            />
              </Link>
            )}
          </div>
        </div>
      </Container>
    </div>
  )
}

export default Solutions

// extracted by mini-css-extract-plugin
export var Heading = "Banner-module--Heading--e8632";
export var bannerCon = "Banner-module--bannerCon--481d9";
export var bannerHeading = "Banner-module--bannerHeading--b4663";
export var btn = "Banner-module--btn--de81f";
export var description = "Banner-module--description--4f433";
export var fintech = "Banner-module--fintech--befbc";
export var freshprepHeading = "Banner-module--freshprepHeading--5c80f";
export var imageAnimation = "Banner-module--imageAnimation--b0979";
export var portfolioAppWorkBanner = "Banner-module--portfolioAppWorkBanner--d2f1d";
export var subTitle = "Banner-module--subTitle--4736d";
export var topToBottomBounce = "Banner-module--topToBottomBounce--101c9";
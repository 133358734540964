// extracted by mini-css-extract-plugin
export var active = "OurServices-module--active--30f8c";
export var btn = "OurServices-module--btn--85ec7";
export var card = "OurServices-module--card--4ddcd";
export var cardBody = "OurServices-module--cardBody--8267a";
export var header = "OurServices-module--header--d862f";
export var leftCard = "OurServices-module--leftCard--2db4b";
export var rightCard = "OurServices-module--rightCard--44601";
export var ripped = "OurServices-module--ripped--92603";
export var section = "OurServices-module--section--144b4";
export var serviceTitleCard = "OurServices-module--serviceTitleCard--fd19a";
export var servicesContainer = "OurServices-module--servicesContainer--c34c1";
export var subTitle = "OurServices-module--subTitle--511f5";
export var techCard = "OurServices-module--techCard--8b145";
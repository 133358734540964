import React from "react"
import * as styles from "./Project.module.scss"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

const benefits = [
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/get_top_15_talent_c3bb51961a.json",
    name: "Get Top 1% Of Engineering Talent",
    details:
      "We attract only top talents to our teams. Our screening criteria are so tough that only the best ones could survive it.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/no_recuritment_delays_d285ea5031.json",
    name: "No Recruitment Delays",
    details:
      "Delays and timeline overruns can be tough. We understand that! So, we have made a pact to always keep our worthy clients from standing in long queues.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/healthy_team_that_comunicate_beter_f8fd472535.json",
    name: "Healthy Team That Communicates Better",
    details:
      "Healthy conversations are one of the major things we have taught our talent to carry out. They talk and figure out blockers like pros.",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/agressive_targets_f9d6c6ced4.json",
    name: "Aggressive Targets",
    details:
      "Not just setting targets but achieving them with no overflows is what our approach looks like",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/more_foucs_on_your_projects_8f9a1330b4.json",
    name: "More Force Into Your Project",
    details:
      "You never know where and when in which part of the world you might find the engineering talent you’ve been looking for forever!",
  },
  {
    icon: "https://invozone-backend.s3.us-east-1.amazonaws.com/offshore_staff_augmentation_c44838650e.json",
    name: "Offshore Staff Augmentation",
    details:
      "​​By partnering with us, you gain access to a pool of talented developers, designers, and IT specialists who integrate into your existing team.",
  },
]

const WhyHireAngular = ({ strapiData, whyHire }) => {
  return (
    <React.Fragment>
      <div
        id="devopsServices"
        className={`${styles.ServicesWhyAngular} ${
          whyHire ? styles.whyHirePadd : ""
        }`}
      >
        <Container>
          <p className={styles.subTitle}>Dedicated Development Teams</p>
          <h2 className={styles.heading}>
            Need More Hands To Tackle Those Big Projects?
          </h2>
          <p className={styles.dec}>
            Got a team that’s not quite clicking? You might need us to jump in
            and lend a hand when things get tricky, and we’ll do it. Our
            software development staff augmentation could be the answer whenever
            you’re in a pickle with too much work and not enough hands.
          </p>
          <Row className={`gap-30 justify-content-start ${styles.cardWrapper}`}>
            {benefits?.map((e, i) => (
              <Col xl={6} md={6} xs={12} key={i} className="gap-30 d-flex">
                <div className={styles.card}>
                  <lottie-player
                    autoplay
                    loop
                    src={e?.icon}
                    style={{ height: "60px", width: "60px", margin: "0" }}
                  />
                  <h3>{e?.name}</h3>
                  <p>{e?.details}</p>
                </div>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default WhyHireAngular
